import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.NOVA]: '',
  [ChainId.KUB]: '',
  [ChainId.REI]: '',
  [ChainId.IVAR]: '0x226974Feb6d4e5774216204A0F3683C5F88be7e0',
  [ChainId.TIVAR]: process.env.REACT_APP_MULTICALL as string,
  [ChainId.LOCAL]: process.env.REACT_APP_MULTICALL as string
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
